// Avoid `console` errors in browsers that lack a console.
(function () {
    let method;
    let noop = function () {
    };
    let methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    let length = methods.length;
    let console = (window.console = window.console || {});
    
    while (length--) {
        method = methods[length];
        
        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

// Debounce Function...
function debounce(func, wait) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            func.apply(context, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

// Add Plugins here ----------------------------------------------------------------------------------------
import 'bootstrap';
import 'jquery-background-video';
import Swiper from 'swiper/bundle';
//import {EffectFade} from "swiper/modules";

// Load Fonts ASAP ----------------------------------------------------------------------------------------
// Loading Adobe typekit fonts in header-scripts.php

// Theme functions -----------------------------------------------------------------------------------------
// Global Scope lets ----------------------------------------------------------------------------------------
let $ = jQuery,
    body = $('body'),
    winHieght = $(window).height(),
    winWidth = $(window).width(),
    headerH = $('#header').outerHeight(),
    scroll_distance;

// Get Header Height for setting body padding
// function bodyTopPadding() {
//     if (body.hasClass('admin-bar')) {
//         console.log('admin-bar class present');
//         let headerH = $('#header').outerHeight();
//         let adminBarHeight = $('#wpadminbar').outerHeight();
//         //let newHeight = headerH + adminBarHeight;
//         $('body').css('padding-top', headerH);
//         $('#header').css('top', adminBarHeight);
//     } else if ( $('#alert-bar').length ) {
//         let headerH = $('#header').outerHeight();
//         $('body').css('padding-top', headerH);
//
//     } else {
//         let headerH = $('#header').outerHeight();
//         $('body').css('padding-top', headerH);
//     }
// }

function dropdownMenus() {
    let winWidth = $(window).width();
    if (winWidth >= 1200) {
        
        $(".navbar .dropdown").mouseenter(function () {
            $(this).find(".dropdown-toggle").dropdown("toggle");
            $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
        });
        
        $(".navbar .dropdown").mouseleave(function () {
            $(this).find(".dropdown-toggle").dropdown("toggle");
            $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
        });
        
        
    } else {
        $('.navbar-toggler').on('click', function () {
            $('.animated-icon2').toggleClass('open');
            $('.navbar').toggleClass('open');
        });
    }
}

// set margin top for main content for certain pages
function mainContentMargin() {
    // if body does not have class home
    if (!body.hasClass('home')) {
        let headerH = $('#header').outerHeight();
        let mainContent = $('#main');
        mainContent.css('margin-top', headerH);
    } else if (($('#alert-bar').length) && (body.hasClass('home'))) {
        let alertBarHeight = $('#alert-bar').outerHeight();
        let mainContent = $('#main');
        mainContent.css('margin-top', alertBarHeight);
    } else {
        // do nothing
    }
}

function alertBar() {
if ($('#alert-bar').length) {
        let alertBar = $('#alert-bar');
        // if class .btn is present in alert bar then add class to alert bar
        if (alertBar.find('.btn').length) {
            alertBar.addClass('has-btn');
        }
    }
}

// Add target _blank to external links & PDF's.
function add_target_blank_to_external_links_and_pdfs() {
    $('a[href$=".pdf"]').addClass('download').attr('target', '_blank');
    $('a[href^="http://"], a[href^="https://"]').not('a[href*="' + location.hostname + '"]').attr('target', '_blank');
}

// Banner Swiper Function
function bannerSwiper() {
    if ($(".banner-slider").length) {
        const bannerSwiper = new Swiper('.banner-slider', {
            direction: 'horizontal',
            loop: true,
            speed: 5000,
            preloadImages: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
        });
    }
}

// Banner Video Function
function bannerVideo() {
    if ($(".my-background-video").length) {
        $('.my-background-video').bgVideo({
            fullScreen: false, // Sets the video to be fixed to the full window - your <video> and it's container should be direct descendents of the <body> tag
            fadeIn: 0, // Milliseconds to fade video in/out (0 for no fade)
            pauseAfter: 0, // Seconds to play before pausing (0 for forever)
            fadeOnPause: false, // For all (including manual) pauses
            fadeOnEnd: true, // When we've reached the pauseAfter time
            showPausePlay: false, // Show pause/play button
            pausePlayXPos: 'right', // left|right|center
            pausePlayYPos: 'top', // top|bottom|center
            pausePlayXOffset: '15px', // pixels or percent from side - ignored if positioned center
            pausePlayYOffset: '15px' // pixels or percent from top/bottom - ignored if positioned center
        });
    }
}

// Before/After Swiper.js with Twentytwenty.js Function from single-work.php
// if before-after class is present
function beforeAfterSwiper() {
    if ($(".before-after").length) {
        
        // Docs:
        // https://swiperjs.com/swiper-api
        // https://github.com/zurb/twentytwenty?tab=readme-ov-file
        
        // Swiper.js
        const swiper = new Swiper('.before-after-swiper', {
            direction: 'horizontal',
            slidesPerView: 1,
            loop: true,
            speed: 1500,
            preloadImages: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: false
            },
            visibilityFullFit: true,
            allowTouchMove: false,
            createElements: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            
        });
        
        // Twentytwenty.js
        $(".twentytwenty-container").twentytwenty({
            default_offset_pct: 0.47, // How much of the before image is visible when the page loads
            orientation: 'horizontal', // Orientation of the before and after images ('horizontal' or 'vertical')
            before_label: 'Before', // Set a custom before label
            after_label: 'After', // Set a custom after label
            no_overlay: false, //Do not show the overlay with before and after
            move_slider_on_hover: false, // Move slider on mouse hover?
            move_with_handle_only: true, // Allow a user to swipe anywhere on the image to control slider movement.
            click_to_move: true // Allow a user to click (or tap) anywhere on the image to move the slider to that location.
        });
        
        // on resize
        $(window).on('resize', function () {
            swiper.update();
        });
        
    }
}

function manageWorkItems() {
    if (!('body.page-template-page-work').length) {
        return;
    }
    
    let workItems				= $('.grid-item');
    let workItemsLength			= workItems.length;
    let winWidth				= $(window).width();
    let loadMoreButton			= $('#load-more-work');
    //let currentPosts			= loadMoreButton.data('current-posts');
    let totalPosts				= loadMoreButton.data('total-posts');
    let currentProjectFilters 	= [];
    let currentServiceFilters 	= [];
    let hideElementClass 		= '.grid-item.hide';
    let showPosts 				= 3;
    let showMorePosts 			= 3;
    let largeIndices 			= [0, 6, 12, 15, 21, 26, 29]; // Add more indices as needed
    
    $('#work-filter #project-type-filter input[type="checkbox"]').change(function(){
        if($(this).is(':checked')){
            currentProjectFilters.push($(this).attr('id'));
        } else {
            var index = currentProjectFilters.indexOf($(this).attr('id'));
            if (index > -1) {
                currentProjectFilters.splice(index, 1);
            }
        }
        addRemoveElements(currentProjectFilters, 'project-type');
        
    });
    
    $('#work-filter #service-filter input[type="checkbox"]').change(function(){
        if($(this).is(':checked')){
            currentServiceFilters.push($(this).attr('id'));
        } else {
            var index = currentServiceFilters.indexOf($(this).attr('id'));
            if (index > -1) {
                currentServiceFilters.splice(index, 1);
            }
        }
        addRemoveElements(currentServiceFilters, 'service-type');
    });
    
    if ((winWidth >= 768) && (winWidth < 1200)) {
        
        showPosts		= 3;
        showMorePosts	= 3;
        largeIndices	= [0, 4, 6, 10, 12, 16, 22, 24]; // Add more indices as needed
        
    } else if (winWidth >= 1200) {
        
        showPosts		= 10;
        showMorePosts	= 5;
        largeIndices	= [0, 6, 12, 15, 21, 26, 29]; // Add more indices as needed
        
    } else {
        
        showPosts		= 5;
        showMorePosts	= 5;
        largeIndices	= [0, 5, 10, 15, 20, 25, 30]; // Add more indices as needed
        
    }
    
    loadMoreButtonFunc();
    
    function loadMoreButtonFunc(){
        
        workItems.removeClass('large-grid-item').addClass('small-grid-item');
        
        loadMoreButton.off('click');
        loadMoreButton.show();
        workItems.not(hideElementClass).show();
        totalPosts = workItems.not(hideElementClass).length;
        
        loadMoreButton.data('current-posts', showPosts);
        let currentPosts = loadMoreButton.data('current-posts');
        let gridItems = $('.grid-item:not(.hide)');
        
        gridItems.each(function (index) {
            if (largeIndices.includes(index)) {
                $(this).removeClass('small-grid-item').addClass('large-grid-item');
            }
        });
        
        if (totalPosts > showPosts) {
            gridItems.slice(showPosts).hide();
        }
        
        if(currentPosts < totalPosts){
            loadMoreButton.on('click', function () {
                gridItems.slice(currentPosts, currentPosts + showPosts).fadeIn();
                currentPosts += showPosts;
                loadMoreButton.data('current-posts', currentPosts);
                if (currentPosts >= totalPosts) {
                    loadMoreButton.remove();
                }
            });
        }else{
            loadMoreButton.remove();
        }
        
    }
    
    function addRemoveElements(arrayElements, type){
        
        workItems.each(function (index) {
            if(arrayElements.length == 0){
                $(this).removeClass('hide');
            }else{
                let filters			= $(this).data(type);
                let filtersArray	= filters.split(",");
                let $t				= $(this);
                if(filtersArray.length > 0){
                    $.each(filtersArray, function(index, item) {
                        if (jQuery.inArray($.trim(item), arrayElements) !== -1) {
                            $t.removeClass('hide');
                            return false;
                        }else{
                            $t.addClass('hide');
                        }
                    });
                }else{
                    if (jQuery.inArray($(this).data(type), arrayElements) !== -1) {
                        $(this).removeClass('hide');
                    }else{
                        $(this).addClass('hide');
                    }
                }
                
            }
        });
        
        loadMoreButtonFunc();
        
    }
    
}

function manageNewsItems() {
    if (!('body.page-template-page-news').length) {
        return;
    }
    
    let newsItems = $('.news-item');
    let newsItemsLength = newsItems.length;
    let winWidth = $(window).width();
    let loadMoreButton = $('#load-more-news');
    //let currentPosts = loadMoreButton.data('current-posts');
    let totalPosts = loadMoreButton.data('total-posts');
    
    // On mobile and tablet only show the first 13 items and then load 6 more at a time. On desktop, show the first 19 items and then load 6 more at a time.
    
    if ((winWidth >= 768) && (winWidth < 1200)) {
        loadMoreButton.data('current-posts', 12);
        let currentPosts = loadMoreButton.data('current-posts');
        
        if (newsItemsLength > 12) {
            newsItems.slice(12).hide();
        }
        
        loadMoreButton.on('click', function () {
            newsItems.slice(currentPosts, currentPosts + 6).fadeIn();
            currentPosts += 6;
            loadMoreButton.data('current-posts', currentPosts);
            if (currentPosts >= totalPosts) {
                loadMoreButton.hide();
            }
        });
    } else if (winWidth >= 1200) {
        loadMoreButton.data('current-posts', 18);
        let currentPosts = loadMoreButton.data('current-posts');
        
        if (newsItemsLength > 18) {
            newsItems.slice(18).hide();
        }
        
        loadMoreButton.on('click', function () {
            newsItems.slice(currentPosts, currentPosts + 6).fadeIn();
            currentPosts += 6;
            loadMoreButton.data('current-posts', currentPosts);
            if (currentPosts >= totalPosts) {
                loadMoreButton.hide();
            }
        });
    } else {
        loadMoreButton.data('current-posts', 9);
        let currentPosts = loadMoreButton.data('current-posts');
        
        if (newsItemsLength > 9) {
            newsItems.slice(9).hide();
        }
        
        loadMoreButton.on('click', function () {
            newsItems.slice(currentPosts, currentPosts + 6).fadeIn();
            currentPosts += 6;
            loadMoreButton.data('current-posts', currentPosts);
            if (currentPosts >= totalPosts) {
                loadMoreButton.hide();
            }
        });
    }
    
    $('#news-filter').on('change', function () {
        let selectedCats = [];
        let selectedItems = [];
        let selectedItemsLength;
        
        $('#cat-filter input[type="checkbox"]:checked').each(function () {
            selectedCats.push($(this).val());
        });
        
        if (selectedCats.length === 0) {
            newsItems.show();
            $('#no-matches-message').hide(); // Hide the message
        } else {
            newsItems.hide();
            selectedItems = newsItems.filter(function () {
                let cats = $(this).data('cats').split(',');
                for (let i = 0; i < cats.length; i++) {
                    if (selectedCats.includes(cats[i])) {
                        return true;
                    }
                }
                return false;
            });
            selectedItemsLength = selectedItems.length;
            if (selectedItemsLength > 13) {
                selectedItems.slice(13).hide();
            }
            selectedItems.show();
            
            // Check if there are no selected items
            if (selectedItemsLength === 0) {
                // Display a message to the user
                $('#no-matches-message').show(); // Show the message
            } else {
                $('#no-matches-message').hide(); // Hide the message
            }
        }
        
        loadMoreButton.data('current-posts', 13);
        loadMoreButton.show();
        
    });
    
    
}


// Theme jQuery here ---------------------------------------------------------------------------------------
$(document).ready(function ($) {
    
    "use strict";
    
    $('html').removeClass('no-js').addClass('js');
    
    alertBar();
    
    dropdownMenus();
    
    manageWorkItems();
    
    manageNewsItems()
    
    add_target_blank_to_external_links_and_pdfs();
    
    //Adds dropshadow class to fixed header on scroll...
    $(window).scroll(function () {
        let scroll = $(window).scrollTop();
        let winHeight = $('#header').height();
        let winHeight2 = winHeight * 5;
        
        if (scroll > 0) {
            $("#header").addClass('shadow');
            //$("#alert-bar").slideUp(200);
            if (scroll > 500) {
                $("#alert-bar").slideUp(200);
            }
        } else {
            $("#header").removeClass("shadow");
            $("#alert-bar").slideDown(200);
        }
        
    });
    
    $("#toTop").click(function () {
        $("html, body").animate({scrollTop: 0}, 500);
    });
    
    // if on body.page-template-page-work click outside of id work-filter remove attribute open on details tag
    $(document).on('click', function (e) {
        if (!$(e.target).closest('.myselectlist').length) {
            $('.myselectlist').removeAttr('open');
        }
    });
    
    // Lightbox for single work page -- https://github.com/osvaldasvalutis/imageLightbox.js
    $(function () {
        if (body.hasClass('single-work')) {
            // ACTIVITY INDICATOR
            const activityIndicatorOn = () => {
                $('<div>', {id: 'imagelightbox-loading'}).append('<div>').appendTo('body');
            };
            
            const activityIndicatorOff = () => {
                $('#imagelightbox-loading').remove();
            };
            
            // OVERLAY
            const overlayOn = () => {
                $('<div>', {id: 'imagelightbox-overlay'}).appendTo('body');
            };
            
            const overlayOff = () => {
                $('#imagelightbox-overlay').remove();
            };
            
            // CLOSE BUTTON
            const closeButtonOn = (instance) => {
                $('<button>', {
                    id: 'imagelightbox-close',
                    title: 'Close',
                    text: '',
                    click: function () {
                        $(this).remove();
                        instance.quitImageLightbox();
                        return false;
                    }
                }).appendTo('body');
            };
            
            const closeButtonOff = () => {
                $('#imagelightbox-close').remove();
            };
            
            // ARROWS
            const arrowsOn = (instance, selector) => {
                const $arrows = $('<button>', {class: 'imagelightbox-arrow imagelightbox-arrow-left'})
                    .add($('<button>', {class: 'imagelightbox-arrow imagelightbox-arrow-right'}));
                
                $arrows.appendTo('body');
                
                $arrows.on('click touchend', function (e) {
                    e.preventDefault();
                    
                    const $this = $(this);
                    const $target = $(`${selector}[href="${$('#imagelightbox').attr('src')}"]`);
                    let index = $target.index(selector);
                    
                    if ($this.hasClass('imagelightbox-arrow-left')) {
                        index = index - 1;
                        if (!$(selector).eq(index).length) {
                            index = $(selector).length;
                        }
                    } else {
                        index = index + 1;
                        if (!$(selector).eq(index).length) {
                            index = 0;
                        }
                    }
                    
                    instance.switchImageLightbox(index);
                    return false;
                });
            };
            
            const arrowsOff = () => {
                $('.imagelightbox-arrow').remove();
            };
            
            // ALL COMBINED
            const selectorF = 'a[data-imagelightbox="lb"]';
            const instanceF = $(selectorF).imageLightbox({
                onStart: () => {
                    overlayOn();
                    closeButtonOn(instanceF);
                    arrowsOn(instanceF, selectorF);
                },
                onEnd: () => {
                    overlayOff();
                    closeButtonOff();
                    arrowsOff();
                    activityIndicatorOff();
                },
                onLoadStart: () => {
                    activityIndicatorOn();
                },
                onLoadEnd: () => {
                    activityIndicatorOff();
                    $('.imagelightbox-arrow').css('display', 'block');
                }
            });
        }
    });
    
    //console.log("document loaded");
    
});

// Once everything is loaded...
$(window).on('load', function () {
    
    "use strict";
    
    $("#header, #main, #footer").css("opacity", "1");
    
    mainContentMargin();
    
    bannerSwiper();
    
    bannerVideo();
    
    beforeAfterSwiper()
    
    //$('.loading').fadeOut(250);
    
    debounce(function () {
        $('#nojswrap').remove();
        //$("#banner .bannerbg").css("opacity", "1");
    }, 250);
    
    //console.log("window loaded");
    
});

// // On resize...
$(window).on('resize', function () {
    
    "use strict";
    
    // debounce mainContentMargin function and dropdownMenus function
    debounce(mainContentMargin(), 150);
    debounce(dropdownMenus(), 150);
    //debounce(manageWorkItems(), 150);
    //debounce(manageNewsItems(), 150);
    
    //console.log("window resized");
    
});